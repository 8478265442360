<template>
  <div>
    <CContainer>
      <CRow>
        <CCol md="6">
          <CCardGroup>
            <CCard>
              <CCardHeader>
                <strong v-if="$route.params.id">
                  Редактирование записи в справочнике
                </strong>
                <strong v-else>
                  Создание записи в справочнике
                </strong>
              </CCardHeader>
              <CCardBody>
                <CForm @submit.prevent="handleSubmit">
                  <CInput
                    v-model="name"
                    label="Введите название записи в справочнике"
                    placeholder="Название записи в справочнике"
                  >
                  </CInput>
                  <CRow>
                    <CCol>
                      <CButton
                        v-if="$route.params.id"
                        color="success"
                        type="submit"
                      >
                        Сохранить
                      </CButton>
                      <CButton
                        v-else
                        color="success"
                        type="submit"
                      >
                        Создать
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: 'DictionaryEntryForm',
  data () {
    return {
      name: ''
    }
  },
  computed: {
    ...mapState({
      dictionaryColors: (state) => state.dictionaryColors.all.dictionaryEntry,
      dictionaryCoverings: (state) => state.dictionaryCoverings.all.dictionaryEntry,
      dictionaryMountTypes: (state) => state.dictionaryMountTypes.all.dictionaryEntry,
      dictionarySputterings: (state) => state.dictionarySputterings.all.dictionaryEntry
    }),
  },
  created() {
    if (this.$route.params.id) {
      if (this.$route.params.namespace === "dictionary-coverings") {
        this["dictionaryCoverings/getById"](this.$route.params.id);
      } else if (this.$route.params.namespace === "dictionary-mount-types") {
        this["dictionaryMountTypes/getById"](this.$route.params.id);
      } else if (this.$route.params.namespace === "dictionary-sputterings") {
        this["dictionarySputterings/getById"](this.$route.params.id);
      }
    }
  },
  methods: {
    ...mapActions([
      "dictionaryCoverings/getById",
      "dictionaryCoverings/create",
      "dictionaryCoverings/update",
      "dictionaryMountTypes/getById",
      "dictionaryMountTypes/create",
      "dictionaryMountTypes/update",
      "dictionarySputterings/getById",
      "dictionarySputterings/create",
      "dictionarySputterings/update"
    ]),
    handleSubmit () {
      if (this.$route.params.id) {
        const payload = {
          id: this.$route.params.id,
          name: this.name
        };

        if (this.$route.params.namespace === "dictionary-coverings") {
          this["dictionaryCoverings/update"](payload);
        } else if (this.$route.params.namespace === "dictionary-mount-types") {
          this["dictionaryMountTypes/update"](payload);
        } else if (this.$route.params.namespace === "dictionary-sputterings") {
          this["dictionarySputterings/update"](payload);
        }
      } else {
        const payload = {
          name: this.name
        };

        if (this.$route.params.namespace === "dictionary-coverings") {
          this["dictionaryCoverings/create"](payload);
        } else if (this.$route.params.namespace === "dictionary-mount-types") {
          this["dictionaryMountTypes/create"](payload);
        } else if (this.$route.params.namespace === "dictionary-sputterings") {
          this["dictionarySputterings/create"](payload);
        }
      }
    }
  },
  watch: {
    dictionaryColors: function (val) {
      if (val) {
        this.name = val.name;
      }
    },
    dictionaryCoverings: function (val) {
      if (val) {
        this.name = val.name;
      }
    },
    dictionaryMountTypes: function (val) {
      if (val) {
        this.name = val.name;
      }
    },
    dictionarySputterings: function (val) {
      if (val) {
        this.name = val.name;
      }
    },
  }
}
</script>
